var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-card"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'product',
        params: _vm.paramsProductRoute
      }
    }
  }, [_vm.isProductSoldOut ? _c('div', {
    staticClass: "product-card__sold-out-badge product-badge"
  }, [_vm._v(" Agotado ")]) : _vm.productDiscount ? _c('div', {
    staticClass: "product-card__discount-badge product-badge"
  }, [_vm._v(" Descuento " + _vm._s(_vm.productDiscount) + "% ")]) : _vm._e(), _c('div', {
    staticClass: "product-card__image"
  }, [_vm.product.mainImage ? _c('img', {
    attrs: {
      "src": _vm.product.mainImage
    }
  }) : _c('img', {
    staticClass: "image-placeholder",
    attrs: {
      "src": require("@/assets/icon-item-default.svg")
    }
  })]), _c('h5', {
    staticClass: "product-card__name"
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")]), _c('div', {
    staticClass: "product-card__price"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.product.finalPrice)) + " ")]), _vm.showFinalPriceBeforeDiscounts ? _c('span', {
    staticClass: "product-card__price__before-discount"
  }, [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.product.finalPriceBeforeDiscounts)) + " ")]) : _vm._e(), _vm.product.hasNoIvaDays ? _c('div', {
    staticClass: "product-card__no-iva-badge"
  }, [_vm._v("Sin IVA")]) : _vm._e()])]), _c('button', {
    staticClass: "product-card__button",
    class: {
      'is-loading': _vm.isAdding
    },
    attrs: {
      "disabled": _vm.isAdding || _vm.isProductSoldOut
    },
    on: {
      "click": _vm.checkInventory
    }
  }, [_vm.isAdding ? _c('Loading', {
    attrs: {
      "loading": _vm.isAdding,
      "size": "small"
    }
  }) : _c('span', [_vm._v(" Agregar al carrito ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }