import service from '@/services/shipping'
import store from '@/store'

const actions = {
  getShippingMethods({ dispatch }, payload) {
    const idStore = store.get('shop/shop.id')
    store.set('shipping/loadingShipping', true)
    return service.getShippingMethods(idStore, payload)
      .then(res => {
        if (res && res.shippingMethods) {
          store.set('shipping/shippingMethods', res.shippingMethods)
          return res.shippingMethods
        } else {
          setTimeout(() => {
            dispatch('getShippingMethods')
          }, 5000)
          return false
        }
      })
      .catch(e => e)
      .finally(() => {
        store.set('shipping/loadingShipping', false)
      })
  }
}

export default actions
