<template>
  <section class="section_product">
    <banner-has-no-iva-days />

    <div v-if="isLoading" class="product__loading">
      <app-loading :loading="true">
        <h4 class="product__loading-text">
          Preparando detalles del producto.. <br />
          ¡Ya casi estamos listos!
        </h4>
      </app-loading>
    </div>

    <ProductNotFound v-else-if="hasError" />

    <div v-else-if="productDetail" class="wrapper-product">
      <div class="container-detail">
        <div class="columns">
          <div class="column is-8 section-image">
            <h1 class="product-name">{{ productDetail.name }}</h1>
            <div class="image-main">
              <img v-if="mainImageUrl" :src="mainImageUrl" />
              <img v-else class="image-default" src="@/assets/icon-item-default.svg" />
            </div>
            <div v-if="discountPercentage" class="label-discount">
              {{ discountPercentage }}% DCTO
            </div>
          </div>

          <div class="column is-4 column-collection">
            <div class="wrapper-top">
              <div class="container-description">
                <div class="description">
                  <h3 class="label-detail">Descripción</h3>
                  <p class="description-text">
                    {{ productDetail.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="wrapper-bottom">
              <div class="container-variants" v-if="shouldShowVariants">
                <app-variant-product :product="productDetail" :errorMessage="errorMessage"
                  @updateVariantsSelected="updateVariantsSelected" />
              </div>
              <div v-if="availableStockQuantity || isProductSoldOut" class="product__stock">
                <p v-if="availableStockQuantity && !isProductSoldOut" class="product__stock__remaining-stock">
                  Solo <strong>{{ availableStockQuantity }} unidades</strong> disponibles
                </p>
                <p v-else-if="isProductSoldOut" class="product__stock__sold-out">
                  PRODUCTO AGOTADO
                </p>
              </div>
              <div class="container-price">
                <div class="level is-flex">
                  <div class="price">
                    <p v-if="productDetail.hasNoIvaDays" class="labelHasNoIvaDays">Sin IVA</p>
                    <p v-if="shouldShowFinalPriceBeforeDiscounts" class="priceDiscount">
                      {{ $currencyFormatter(productDetail.finalPriceBeforeDiscounts) }}
                    </p>
                    <p>{{ $currencyFormatter(productDetail.finalPrice) }}</p>
                  </div>
                  <AppMoreOrLessProduct v-if="!isProductSoldOut" :quantity="quantity"
                    :max-quantity="availableStockQuantity" :product="productDetail"
                    :sell-without-stock="sellWithoutStock" @updateQuantity="setQuantity" />
                </div>
              </div>
              <div class="container-button">
                <button class="button is-fullwidth" :disabled="shouldDisableAddToCartButton"
                  :class="{ 'is-loading': adding }" @click="checkInventory">
                  <span class="icon">
                    <inline-svg :src="require('@/assets/icon-shopping-car.svg')" />
                  </span>
                  <span>AGREGAR AL CARRITO</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns secondary-images">
        <div class="column is-8">
          <carousel :per-page="isMobile ? 3 : 5" :pagination-padding="isMobile ? 3 : 6" :pagination-size="8"
            pagination-active-color="#A3ADC2" :mouse-drag="false">
            <slide v-for="(image, index) in productDetail.images" :key="index" class="slide-image">
              <div class="secondary-image link" :class="{ selected: imageSelected.id == image.id }"
                @click="imageSelected = image">
                <img :src="image.url" />
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '@/store'
import variantProductUtil from '@/utils/variantProduct'
import { PRODUCT_TYPES } from '@/utils/constants'

import AppMoreOrLessProduct from '@/components/shared/MoreOrLessProduct'
import AppLoading from '@/components/shared/Loading'
import AppVariantProduct from '@/components/shared/VariantProduct'
import BannerHasNoIvaDays from '@/components/layout/BannerHasNoIvaDays.vue'
import ProductNotFound from '@/components/products/ProductNotFound'

export default {
  components: {
    AppMoreOrLessProduct,
    AppLoading,
    AppVariantProduct,
    BannerHasNoIvaDays,
    ProductNotFound
  },
  metaInfo() {
    const title = 'Tienda'
    const description = '¡Visita nuestra tienda online!'
    // const title = `${(this.productDetail ? this.productDetail.name : '') + ' - '}${this.shop.name}`
    // let description = this.productDetail ? this.productDetail.description : ''
    // if (!description && this.productDetail) {
    //   const categoryName =
    //     this.productDetail.category && this.productDetail.category.name
    //       ? ', parte de nuestra categoría ' + this.productDetail.category.name
    //       : ''
    //   description = `Descubre ${this.productDetail.name}${categoryName}`
    // }

    const meta = [
      { property: 'title', content: title },
      { property: 'og:title', content: title },
      // { property: 'og:site_name', content: `${this.shop.name}` },
      { property: 'og:site_name', content: title },
      { property: 'og:type', content: 'product' },
      { property: 'og:url', content: `https://${this.shop.domain}${this.$route.path}` },
      { name: 'twitter:card', content: 'summary_large_image' }
    ]
    meta.push({ property: 'description', content: description })
    meta.push({ property: 'og:description', content: description })

    return {
      title,
      meta
    }
  },
  props: {
    origin: { type: Object, required: false }
  },
  data() {
    return {
      quantity: 1,
      adding: false,
      errorMessage: '',
      timeOutError: null,
      variantsSelected: [],
      selectedVariant: null,
      imageSelected: {},
      availableStockQuantity: null,
      sellWithoutStock: true,
      isLoading: false,
      hasError: false
    }
  },
  computed: {
    ...mapState('shop', ['shop']),
    ...mapState('products', ['productDetail']),
    ...mapGetters('app', ['isMobile']),
    shouldDisableAddToCartButton() {
      if (this.productHasVariants && this.selectedVariant === null) return true

      return this.adding || this.isProductSoldOut
    },
    productHasVariants() {
      return this.productDetail.type === PRODUCT_TYPES.VARIANT_PARENT
    },
    isProductSoldOut() {
      let product = this.productDetail

      if (this.productHasVariants) {
        product = this.selectedVariant
      }

      const { sellWithoutStock, quantity } = product?.inventorySettings || {}

      if (sellWithoutStock || sellWithoutStock === undefined || !product.inventory) {
        return false
      }

      return quantity <= 0
    },
    discountPercentage() {
      return this.productDetail.discounts?.discountPercentage
    },
    shouldShowVariants() {
      const { variantAttributes } = this.productDetail

      return this.productHasVariants && !!variantAttributes.length
    },
    shouldShowFinalPriceBeforeDiscounts() {
      const { finalPriceBeforeDiscounts, finalPrice } = this.productDetail

      return finalPriceBeforeDiscounts !== finalPrice
    },
    mainImageUrl() {
      if (this.selectedVariant?.mainImage || this.imageSelected.url) {
        return this.selectedVariant ? this.selectedVariant.mainImage : this.imageSelected.url
      } else if (this.productDetail?.mainImage) {
        return this.productDetail.mainImage
      }

      return ''
    }
  },
  watch: {
    productDetail: function(product) {
      if (product?.images) {
        product.images.map(image => {
          if (image.type === 'primary') {
            this.imageSelected = image
          }
        })
      }
    }
  },
  created() {
    this.getProduct()
  },
  mounted() {
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } catch (e) {}
  },
  methods: {
    ...mapActions('products', ['getProductById']),
    ...mapActions('cart', ['addProductCart']),
    ...mapActions('notification', ['showDangerNotification']),
    getProduct() {
      store.set('products/productDetail', null)
      if (this.origin && this.origin.type !== PRODUCT_TYPES.VARIANT) {
        if (this.origin.type === PRODUCT_TYPES.VARIANT_PARENT) {
          this.loadServerProduct(this.origin.id)
        } else {
          store.set('products/productDetail', this.origin)
        }
      } else {
        const product = this.$route.params.product.split('-')
        if (product.length > 1) {
          this.loadServerProduct(product[product.length - 1])
        } else {
          this.$router.replace({ name: '404' })
        }
      }
    },
    updateVariantsSelected(val) {
      this.variantsSelected = val
      const res = variantProductUtil.searchVariant(this.productDetail, this.variantsSelected)
      if (res.status === 'SUCCESS') {
        this.selectedVariant = res.variant
      } else if (this.selectedVariant) {
        this.selectedVariant = null
      }
    },
    async checkInventory() {
      this.adding = true

      const { inventory, inventorySettings = {} } = await this.getInventory()

      const shouldCheckForStock = inventory && !inventorySettings?.sellWithoutStock
      const hasNoStockForOrder = this.quantity > inventorySettings?.quantity
      const shouldShowNoStockError = shouldCheckForStock && hasNoStockForOrder

      if (shouldShowNoStockError) {
        store.dispatch('notification/showDangerNotification', {
          text: 'Lo sentimos, no contamos con stock suficiente para tu pedido'
        })

        this.sellWithoutStock = inventorySettings?.sellWithoutStock
        this.quantity = inventorySettings?.quantity > 0 ? inventorySettings?.quantity : 1

        this.adding = false

        const stockIsEmpty = inventorySettings?.quantity <= 0
        if (stockIsEmpty) {
          this.loadServerProduct(this.productDetail.id)
        } else {
          this.availableStockQuantity = inventorySettings?.quantity
        }
      } else {
        this.selectProduct()
      }
    },
    async getInventory() {
      try {
        let productToGetInventoryOf = this.productDetail

        if (this.productHasVariants) {
          productToGetInventoryOf = this.selectedVariant
        }

        const product = await this.getProductById({
          idProduct: productToGetInventoryOf.id,
          shouldSetProduct: false
        })

        return {
          inventory: product.inventory,
          inventorySettings: product.inventorySettings
        }
      } catch (e) {
        store.dispatch('notification/showDangerNotification', {
          text: 'Error creando tu pedido, puedes intentar de nuevo en unos minutos.'
        })
        this.adding = false
        return {}
      }
    },
    async selectProduct() {
      let product = this.productDetail

      if (this.productDetail.type === PRODUCT_TYPES.VARIANT_PARENT) {
        const res = variantProductUtil.searchVariant(product, this.variantsSelected)

        if (res.status === 'SUCCESS') {
          product = res.variant
        } else {
          this.adding = false
          this.errorMessage = res.error.message

          if (res.error.variantId) {
            document.getElementById(`select-${res.error.variantId}`).classList.add('error')
          }

          clearTimeout(this.timeOutError)

          this.timeOutError = setTimeout(() => {
            this.errorMessage = ''
          }, 4000)

          return false
        }
      }

      this.addProductCart({ product, quantity: this.quantity })
      this.quantity = 1
      this.adding = false
    },
    setQuantity(quantity) {
      this.quantity = quantity
    },
    async loadServerProduct(idProduct) {
      this.isLoading = true

      try {
        const product = await this.getProductById({ idProduct })

        if (product.category) {
          store.set('categories/categorieSelected', { id: product.category.id })
        }
      } catch (e) {
        if (e.status === 404) {
          this.$router.replace({ name: '404' })
        }

        this.hasError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product__loading {
  margin-top: 20vh;
}

.product__loading-text {
  text-align: center;
  color: #334155;
  font-size: 16px;
  line-height: 28px;
}

.loader-wrapper {
  margin-top: 80px;
}
.container-detail {
  padding: 0;
  background-color: #fff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
}
.column {
  padding: 0;
}
.column-collection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f7f9fc;
  min-height: 280px;
  padding: 20px 25px;
  .wrapper-top {
    min-height: 150px;
  }
  .wrapper-bottom {
    display: flex;
    flex-direction: column;
    .container-variants {
      margin: 10px 0;
    }
    .container-price {
      margin-bottom: 20px;
    }
    .container-button {
      margin-top: 10px;
      width: 100%;
    }
  }
}
h1 {
  margin-left: 31px;
  margin-top: 17px;
  margin-right: 31px;
}
h2 {
  font-size: 14px;
  color: #042921;
  margin-top: 18px;
  margin-left: 16px;
  margin-bottom: 45px;
}
.description {
  margin-bottom: 50px;
}
.label-detail {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
}
.description-text {
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 39px;
}
.price {
  font-size: 22px;
  color: #00b19d;
  .priceDiscount {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: line-through;
    margin-bottom: -5px;
    color: #c2c9d6;
  }
}
.button {
  background: var(--color-tertiary);
  border: 1px solid var(--color-tertiary);
  box-sizing: border-box;
  color: #fff;
  font-size: 11px;
  border-radius: 0;
  line-height: 24px;
  text-transform: uppercase;
  height: 36px;
  .icon svg {
    stroke: #fff;
    width: 18px;
    height: 14px;
    margin-right: 5px;
  }
}
.section-image {
  position: relative;
  .image-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 100%;
    margin: 0;
    img {
      max-width: 100%;
      min-height: 280px;
      max-height: 380px;
    }
    img.image-default {
      min-height: 200px;
      max-height: 250px;
    }
  }
  .label-discount {
    position: absolute;
    padding: 2px 4px;
    right: 15px;
    top: 15px;
    background: #e85e42;
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
  }
}
.columns {
  margin: 0;
}
.labelHasNoIvaDays {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 63px;
  background: #cffffa;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #00b19d;
  margin-bottom: 5px;
}
.container-more-or-less {
  margin-top: auto;
}
.secondary-images {
  margin-top: 12px;
  .slide-image {
    padding: 0 4px;
  }
  .secondary-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: #fff;
    border-radius: 8px;
    img {
      max-height: 90%;
      max-width: 80%;
    }
  }
  .secondary-image.selected {
    border: 1px solid #4a90e2;
  }
}

.product__stock {
  margin-top: 20px;
  color: #e85e42;

  .product__stock__sold-out {
    font-size: 12px;
    font-weight: 600;
  }
  .product__stock__remaining-stock {
    font-size: 14px;

    strong {
      color: inherit;
    }
  }
}

@media (max-width: 768px) {
  .wrapper-product {
    position: relative;
    width: 100%;
    h1 {
      padding-top: 10px;
    }
    .image-main {
      height: 300px !important;
      margin-bottom: 100px !important;
      img {
        min-height: 250px !important;
        max-height: 300px !important;
      }
      img.image-default {
        min-height: 200px !important;
        min-height: 220px !important;
      }
    }
    .labelHasNoIvaDays {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #00b19d;
      margin-bottom: 33px;
      text-align: center;
    }
    .label-discount {
      top: inherit !important;
      bottom: 10px;
    }
    .secondary-images {
      position: absolute;
      top: 330px;
      width: 100%;
      .secondary-image {
        height: 60px;
        border: 1px solid #e4e9f6;
        img {
          max-height: 90%;
          max-width: 80%;
        }
      }
      .secondary-image.selected {
        border: 1px solid #4a90e2;
      }
    }
  }
}
</style>
