var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('app-loading', {
    attrs: {
      "loading": !_vm.paymentMethods
    }
  }), _vm.conventionalPayments.length ? _c('div', [_vm._m(0), _c('div', {
    staticClass: "payment-methods"
  }, [_vm._l(_vm.conventionalPayments, function (payment, index) {
    return [_vm.isPaymentMethodVisible(payment.provider) ? _c('app-payment-option', {
      key: index,
      attrs: {
        "option": payment
      },
      on: {
        "select-payment": function ($event) {
          return _vm.selectPayment(payment);
        }
      }
    }) : _vm._e()];
  })], 2)]) : _vm._e(), _vm.otherPayments.length ? _c('div', [_vm._m(1), _c('div', {
    staticClass: "payment-methods"
  }, _vm._l(_vm.otherPayments, function (payment, index) {
    return _c('app-payment-option', {
      key: index,
      attrs: {
        "option": payment
      },
      on: {
        "select-payment": function ($event) {
          return _vm.selectPayment(payment);
        }
      }
    });
  }), 1)]) : _vm._e(), _vm.shippingSelected ? _c('div', [_vm._m(2), _c('div', {
    staticClass: "shipping-method"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.shippingSelected.name))]), _vm.shippingDescription ? _c('div', {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.shippingDescription))]) : _vm._e()])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('h2', [_vm._v(" ELIGE TU MÉTODO DE PAGO ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('h2', [_vm._v(" OTROS MÉTODOS ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-truck.svg")
    }
  })]), _c('h2', [_vm._v(" COSTO DEL ENVÍO ")])]);
}]

export { render, staticRenderFns }