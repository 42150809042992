<template>
  <div>
    <router-link
      v-if="shopLogo"
      class="pointer logo"
      :class="{ disabled: loadingProducts }"
      @click.native="selectCategory({ id: null })"
      to="/"
    >
      <div class="image image-logo">
        <img :src="shopLogo" />
      </div>
    </router-link>
    <div class="categories" v-if="categories && categories.length">
      <router-link
        class="categorie"
        :class="{
          'categorie-active': !categorieSelected || !categorieSelected.id,
          disabled: loadingProducts
        }"
        @click.native="selectCategory({ id: null })"
        to="/"
      >
        <div class="wrapper-name">
          INICIO
          <div class="app-loading" v-if="!categorieSelected || !categorieSelected.id">
            <app-loading :loading="loadingProducts" size="small"> </app-loading>
          </div>
        </div>
      </router-link>
      <router-link
        v-for="(category, index) in isMobile ? categories : categories.slice(0, 6)"
        :key="index"
        class="categorie has-tooltip-arrow has-tooltip-bottom"
        :data-tooltip="category.name.length > 15 && !isMobile ? category.name : null"
        :class="{
          'categorie-active': categorieSelected && categorieSelected.id == category.id,
          disabled: loadingProducts
        }"
        @click.native="selectCategory(category)"
        :to="{
          name: 'category',
          params: {
            category: paramsProductRoute(category.name) + '-' + category.id
          }
        }"
      >
        <div class="wrapper-name">
          {{ category.name }}
          <div class="app-loading" v-if="categorieSelected && categorieSelected.id == category.id">
            <app-loading :loading="loadingProducts" size="small"> </app-loading>
          </div>
        </div>
      </router-link>
      <div v-if="!isMobile && categories.length > 6" class="more">
        <div class="dropdown is-right is-hoverable">
          <div class="dropdown-trigger">
            MÁS
            <span class="icon">
              <img src="@/assets/icon-caret-down.svg" />
            </span>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <router-link
                v-for="(category, index) in categories.slice(6, categories.length)"
                :key="index"
                class="dropdown-item"
                :class="{
                  'categorie-active': categorieSelected && categorieSelected.id == category.id,
                  disabled: loadingProducts
                }"
                @click.native="selectCategory(category)"
                :to="{
                  name: 'category',
                  params: {
                    category: paramsProductRoute(category.name) + '-' + category.id
                  }
                }"
              >
                {{ category.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '@/store'
import urlUtil from '@/utils/url'

import AppLoading from '@/components/shared/Loading'

export default {
  components: {
    AppLoading
  },
  computed: {
    ...mapState('categories', ['categories', 'categorieSelected', 'loadingCategories']),
    ...mapState('products', ['loadingProducts']),
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('shop', ['shopLogo'])
  },
  methods: {
    ...mapActions('products', ['getProducts']),
    selectCategory(category) {
      try {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (e) {}
      store.set('app/showMenuMobile', false)
      store.set('categories/categorieSelected', category)
      store.set('products/products', null)
      store.set('products/metadata', null)
      store.set('products/searchQuery', '')
      store.set('products/searchPage', 0)
      store.set('products/searchInputQuery', '')
      this.getProducts()
    },
    paramsProductRoute(val) {
      return urlUtil.formatSeoUrl(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  display: none;
  .image-logo {
    width: 80%;
    margin-left: 10%;

    img {
      max-height: 100px;
    }
  }
}
.categories {
  display: flex;
  background-color: var(--color-secondary);
  .categorie {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    height: 38px;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    width: 13%;
    cursor: pointer;
    color: var(--color-text);
    .wrapper-name {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .app-loading {
        position: absolute;
        top: 3px;
        left: -22px;
      }
    }
  }
  .categorie.categorie-active {
    background-color: var(--color-secondary);
    border-bottom: 3px solid var(--color-text);
  }
  .more {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9%;
    color: var(--color-text);
    font-size: 14px;
    text-transform: uppercase;
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .dropdown {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    #dropdown-menu {
      max-height: 450px;
      overflow-y: auto;
    }
    .categorie-active.dropdown-item {
      background-color: var(--color-secondary);
      border-left: 3px solid var(--color-border);
      color: var(--color-text);
      border-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .logo {
    display: block;
    .image-logo {
      padding-top: 20px;
    }
  }
  .categories {
    display: block;
    overflow-y: auto;
    max-height: 80vh;
    margin-top: 27px;
    .categorie {
      color: var(--color-text);
      display: block;
      width: 100%;
      padding: 8px 0 0 28px;
    }
    .categorie-active {
      background-color: var(--color-contrast) !important;
      color: #fff;
      border-right: 4px solid var(--color-tertiary);
      border-bottom: 0 !important;
    }
  }
}
</style>
