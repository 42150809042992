<template>
  <div v-if="shoulShowIdentificationForm" class="columns">
    <div v-if="v.billingInfo.identification" class="column is-7">
      <div class="field">
        <label class="label"
          >Número de identificación {{ isIdentificationRequired ? '*' : '' }}</label
        >
        <div class="control">
          <input
            v-model.trim="v.billingInfo.identification.$model"
            class="input"
            :class="{
              error: shouldShowIdentificationError
            }"
            type="text"
            placeholder="Tu número de identificación"
          />
        </div>
        <p v-if="showIdentificationMessage" class="help is-danger">
          Ingresa tu número de identificación
        </p>
        <p v-else-if="!v.billingInfo.identification.numeric" class="help is-danger">
          Ingresa un número de identificación válido
        </p>
        <p v-else-if="!isIdentificationValid" class="help is-danger">
          Ingresa un formato de identificación válido
        </p>
      </div>
    </div>
    <div v-if="v.billingInfo.identificationType" class="column is-5">
      <div class="field">
        <label class="label">
          Tipo de identificación {{ isIdentificationTypeRequired ? '*' : '' }}
        </label>
        <div class="select control is-fullwidth">
          <select v-model.trim="v.billingInfo.identificationType.$model">
            <option
              v-for="(type, indexOption) in identificationTypes"
              :key="indexOption"
              :value="type"
            >
              {{ type.value }}
            </option>
          </select>
        </div>
        <div v-if="showIdentificationTypeMessage">
          <p class="help is-danger">Selecciona un tipo de identificación</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import { VERSIONS } from '@/utils/constants'

const { COLOMBIA, ARGENTINA, PERU, MEXICO, REPUBLICA_DOMINICANA } = VERSIONS

export default {
  name: 'IdentificationClient',
  inject: ['v'],
  computed: {
    applicationVersion: get('shop@shop.config.applicationVersion'),
    identificationTypes: get('config@identificationTypes'),
    shoulShowIdentificationForm() {
      const allowedVersions = [COLOMBIA, ARGENTINA, PERU, MEXICO, REPUBLICA_DOMINICANA]
      return allowedVersions.includes(this.applicationVersion)
    },
    isIdentificationRequired() {
      if (this.v.billingInfo.identification.required === undefined) return false
      return true
    },
    isIdentificationValid() {
      if (this.applicationVersion === PERU && !this.v.billingInfo.identification.isValidDNIPeru) return false
      return true
    },
    isIdentificationTypeRequired() {
      if (this.v.billingInfo.identificationType.required === undefined) return false
      return true
    },
    shouldShowIdentificationError() {
      return (
        this.isIdentificationRequired &&
        this.v.billingInfo.identification.$dirty &&
        this.v.billingInfo.identification.$invalid
      )
    },
    showIdentificationMessage() {
      if (!this.isIdentificationRequired) return false
      return (
        this.v.billingInfo.identification.$dirty &&
        !this.v.billingInfo.identification.required
      )
    },
    showIdentificationTypeMessage() {
      if (!this.isIdentificationTypeRequired) return false
      return (
        this.v.billingInfo.identificationType.$dirty &&
        !this.v.billingInfo.identificationType.required
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
