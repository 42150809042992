<template>
  <section v-if="lastOrder">
    <div class="notification-order level">
      <div class="level-item status">
        <img src="@/assets/icon-truck.svg" class="icon">
        <div class="comlumns title-status">
          <div class="column">
            <h2>
              TU PEDIDO HA SIDO CREADO
            </h2>
            <p v-if="lastOrder.paymentMethod.type == 'manual'">Sigue las instrucciones a continuación <span class="desktop">y coordina con el vendedor</span></p>
          </div>
        </div>
      </div>
      <div class="level-item number-order">
        <h2>Nº PEDIDO</h2>
        <span>{{ lastOrder.number }}</span>
      </div>
    </div>

    <div v-if="!lastOrder.paymentMethod.provider" class="container-custom">
      <div class="title-container">
        <img
          class="logo-payment"
          :src="lastOrder.paymentMethod.logoFiles ? lastOrder.paymentMethod.logoFiles.url : require('@/assets/icon-payment-generic.svg')">
        <h3>{{ lastOrder.paymentMethod.name }}</h3>
      </div>
      <div class="columns">
        <div class="column">
          <div class="pre">{{ lastOrder.paymentMethod.paymentInstructions }}</div>
        </div>
        <div v-if="lastOrder.paymentMethod.instructionsFiles" class="column">
          <div class="container-qr">
              <img
                :src="lastOrder.paymentMethod.instructionsFiles.url">
          </div>
        </div>
      </div>
    </div>

    <div v-if="lastOrder.paymentMethod.provider === 'WHATSAPP'" class="container-whatsapp">
      <div class="header-pay">
        <h2>INSTRUCCIONES DE PAGO</h2>
      </div>
      <div class="container-body">
        <div class="body-pay whatsapp">
          <div class="columns">
            <div class="column is-6">
              <img class="logo-payment" src="@/assets/logo-whatsapp.png">
              <p>¡Muchas gracias por tu pedido! para coordinar el pago haz clic en el botón, <strong>Enviar pedido vía WhatsApp</strong></p>
              <p>Si tienes consultas o inquietudes sobre tu compra puedes comunicarte al teléfono de contacto indicado en la parte inferior.</p>
            </div>
            <img class="img-phone" src="@/assets/phone-whatsapp.png">
          </div>
        </div>
      </div>
    </div>

    <div v-if="lastOrder.paymentMethod.provider == 'NEQUI'" class="container-nequi">
      <div class="columns">
        <div class="column">
          <img class="image-payment" :src="require(`@/assets/logo-full-nequi.png`)">
          <p>Escanea este código QR con tu app Nequi y una vez realizado el pago haz clic en el botón Verificar pago de Nequi.</p>
          <p>Si tienes consultas o inquietudes sobre tu compra puedes comunicarte al teléfono de contacto indicado en la parte inferior.</p>
        </div>
        <div class="column">
          <div class="container-qr">
            <vue-qr
              :text="lastOrder.payments[0].paymentInfo.qr"
              :size="220"
              :margin="0"
            ></vue-qr>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-automatic-pay"
      :class="lastOrder.paymentMethod.provider.toLowerCase()"
      v-if="['PAYU', 'MERCADO_PAGO', 'GREENPAY', 'VISANET'].includes(lastOrder.paymentMethod.provider)"
    >
      <img class="image-payment" :src="require(`@/assets/logo-full-${lastOrder.paymentMethod.provider.toLowerCase()}.png`)">
      <p v-if="!openedExternalPayment">{{redirectText}}</p>
      <app-loading :loading="!openedExternalPayment"></app-loading>
      <p
        class="open-link-pay"
        v-if="['MERCADO_PAGO', 'GREENPAY'].includes(lastOrder.paymentMethod.provider) && openedExternalPayment"
      >
        Si no fuiste redirigido
        <a :href="this.lastOrder.payments[0].paymentInfo.url" target="_blank">haz clic aquí</a>
        para continuar con el pago
      </p>
      <p v-if="['MERCADO_PAGO', 'GREENPAY'].includes(lastOrder.paymentMethod.provider) && openedExternalPayment">
        Luego de realizar el pago, espera un momento y validaremos el estado
      </p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import AppLoading from '@/components/shared/Loading'
import VueQr from 'vue-qr'

export default {
  components: {
    AppLoading,
    VueQr
  },
  computed: {
    ...mapState('order', ['lastOrder', 'openedExternalPayment']),
    redirectText() {
      switch (this.lastOrder.paymentMethod.provider) {
        case 'PAYU':
          return 'Redirigiendo a PayU...'
        case 'MERCADO_PAGO':
          return 'Redirigiendo a Mercado Pago...'
        case 'GREENPAY':
          return 'Redirigiendo a Greenpay...'
        case 'VISANET':
          return 'Redirigiendo a VisaNet...'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader-wrapper {
    margin-top: 80px;
  }
  .notification-order {
    background: #FAFFF5;
    border: 1px solid #F0F4EB;
    border-left: 6px solid #AAD572;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: #29303D;
    height: 57px;
  }
  .number-order span {
    margin-left: 17px;
    font-size: 24px;
  }
  .status{
    justify-content: flex-start;
    padding-left: 14px;
  }
  .number-order{
    justify-content: flex-end;
    padding-right: 22px;
  }
  .title-status{
    margin-left: 16px;
    p {
      font-size: 11px;
      color: #8592ad;
    }
  }
  .container-automatic-pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image-payment {
      margin-top: 30px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: #47536B;
      margin-top: 29px;
    }
    p.open-link-pay {
      color: #9fa2a8;
      a {
        color: #00b19d;
      }
    }
  }
  .container-automatic-pay.visanet {
    img {
      width: 150px;
    }
  }
  .container-custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    .title-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 20px;
      .logo-payment{
        width: 48px;
        margin-right: 10px;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
      }
    }
    .image-payment {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .pre {
      white-space: pre-wrap;
      color: #47536B;
      font-size: 13px;
    }
    .container-qr {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      border: 2px solid #C2C9D6;
      box-sizing: border-box;
      border-radius: 17px;
      width: 254px;
      height: 254px;
      img {
        max-height: 250px;
        min-height: 180px;
      }
    }
  }
  .container-nequi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    .image-payment {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    p {
      font-size: 12px;
      line-height: 21px;
      color: #47536B;
      margin-bottom: 11px;
    }
    .container-qr {
      background: #FFFFFF;
      border: 2px solid #C2C9D6;
      box-sizing: border-box;
      border-radius: 17px;
      width: 254px;
      height: 254px;
      margin: auto;
      margin-top: 20px;
      text-align: center;
      padding-top: 15px;
    }
  }
  .container-whatsapp {
    background: #FFF;
    border: 1px solid #E4E9F6;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    line-height: 21px;
    color: #29303D;
    text-align: justify;
    .logo-payment{
      margin-bottom: 10px;
      width: 48px;
    }
    .body-pay {
      padding: 26px 18px;
      position: relative;
      min-height: 356px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #47536B;
      p {
        margin-bottom: 20px;
      }
      .img-phone{
        right: 0;
        bottom: 0;
        position: absolute;
      }
    }
  }
  .header-pay {
    display: flex;
    align-items: center;
    padding-left: 17px;
    border-bottom: 1px solid #E4E9F6;
    font-size: 10px;
    line-height: 15px;
    height: 27.5px;
  }
  .loader-wrapper{
    margin-top: 40px;
  }

@media (max-width: 768px) {
  .notification-order {
    display: table;
    width: 100%;
    .level-item {
      margin-bottom: 0;
    }
    .number-order {
      justify-content: flex-start;
      padding-left: 68px;
    }
    .status {
      img{
        margin-top: 34px;
      }
    }
  }
  .img-phone {
    width: 25%;
  }
}
</style>
