import store from '@/store'

import orderService from '@/services/order'

const actions = {
  newOrder() {
    return new Promise(async (resolve, reject) => {
      try {
        const idStore = store.get('shop/shop.id')
        const client = store.get('cart/client')
        const billingInfo = store.get('cart/billingInfo')
        const shippingInfo = store.get('cart/address')
        const products = [...store.get('cart/productsInCart')]
        const appliedCoupon = store.get('coupons/appliedCoupon')

        const items = products.map(product => ({
          id: product.id,
          quantity: product.quantity
        }))

        const data = {
          client,
          shippingMethod: {
            id: store.get('shipping/shippingMethods.0.id')
          },
          paymentMethod: {
            id: store.get('cart/paymentMethod.id')
          },
          shippingInfo,
          items,
          discountCoupon: {}
        }

        if (billingInfo) {
          data.billingInfo = billingInfo
        }

        if (appliedCoupon?.id) {
          data.discountCoupon.id = appliedCoupon.id
        }

        const { order } = await orderService.newOrder(idStore, data)
        store.set('order/lastOrder', order)
        store.set('cart/lastOrderProductsInCart', order.items)
        store.dispatch('cart/cleanCart')

        resolve(order)
      } catch (e) {
        store.dispatch('notification/showDangerNotification', {
          text: 'Error creando tu pedido, puedes intentar de nuevo en unos minutos.'
        })
        reject(e)
      }
    })
  },
  getOrder(context, { idOrder, extraAttrs }) {
    const idStore = store.get('shop/shop.id')
    return orderService
      .getOrder(idStore, idOrder, extraAttrs)
      .then(res => {
        if (res && res.order) {
          return res.order
        }
        return false
      })
      .catch(e => e)
  },
  getPayment(context, { idPayment }) {
    const idStore = store.get('shop/shop.id')
    return orderService
      .getPayment(idStore, idPayment)
      .then(res => {
        if (res && res.payment) {
          return res.payment
        }
        return false
      })
      .catch(e => e)
  },
  regretOrder(context, { orderNumber, email }) {
    const idStore = store.get('shop/shop.id')
    return orderService
      .regretOrder(idStore, { orderNumber, email })
      .then(res => {
        if (res) {
          store.set('order/idCancelRequest', res.idCancelRequest)
          return res
        }
        return false
      })
      .catch(e => e)
  }
}

export default actions
