<template>
  <section>
    <app-loading :loading="!paymentMethods"> </app-loading>

    <div v-if="conventionalPayments.length">
      <div class="subtitle">
        <h2>
          ELIGE TU MÉTODO DE PAGO
        </h2>
      </div>
      <div class="payment-methods">
        <template v-for="(payment, index) in conventionalPayments">
          <app-payment-option
            v-if="isPaymentMethodVisible(payment.provider)"
            :key="index"
            :option="payment"
            @select-payment="selectPayment(payment)"
          />
        </template>
      </div>
    </div>

    <div v-if="otherPayments.length">
      <div class="subtitle">
        <h2>
          OTROS MÉTODOS
        </h2>
      </div>
      <div class="payment-methods">
        <app-payment-option
          v-for="(payment, index) in otherPayments"
          :key="index"
          :option="payment"
          @select-payment="selectPayment(payment)"
        />
      </div>
    </div>

    <div v-if="shippingSelected">
      <div class="subtitle">
        <span class="icon">
          <img src="@/assets/icon-truck.svg" />
        </span>
        <h2>
          COSTO DEL ENVÍO
        </h2>
      </div>
      <div class="shipping-method">
        <div class="title">{{ shippingSelected.name }}</div>
        <div v-if="shippingDescription" class="description">{{ shippingDescription }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import EventBus from '@/eventBus'
import store from '@/store'

import AppPaymentOption from '@/components/shopping-cart/payment/paymentOption'
import AppLoading from '@/components/shared/Loading'

export default {
  components: {
    AppPaymentOption,
    AppLoading
  },
  mounted() {
    this.getPaymentMethods()
  },
  computed: {
    ...mapGetters('shipping', ['shippingSelected']),
    ...mapGetters('shop', ['conventionalPayments', 'otherPayments']),
    ...mapState('shop', ['paymentMethods']),
    ...mapGetters('cart', ['subtotal']),
    shippingDescription() {
      let description = ''
      if (this.shippingSelected?.description) {
        description = this.shippingSelected.description
      } else if (this.shippingSelected?.time?.show) {
        const time = this.shippingSelected.time
        description = `Entrega de ${time.minDays} a ${time.maxDays} días hábiles`
      } else if (this.shippingSelected?.minPurchaseForFree) {
        description = `Gratis a partir de compras mayores a ${this.$currencyFormatter(
          this.shippingSelected.minPurchaseForFree
        )}`
      }
      return description
    }
  },
  methods: {
    ...mapActions('shop', ['getPaymentMethods']),
    selectPayment(payment) {
      store.set('cart/paymentMethod', { ...payment })
      EventBus.$emit('NEXT_STEP_CART', { step: 'CONFIRM_SUMMARY' })
    },
    isPaymentMethodVisible(provider) {
      if (provider === 'PAYU') {
        const paymentMethod = this.paymentMethods.find(pm => pm.provider === provider)
        const isEnableMinAmount = paymentMethod.providerConfig.min_amount_option
        const minAmount = paymentMethod.providerConfig.min_amount
        if (isEnableMinAmount && this.subtotal < minAmount) return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  margin-top: 100px;
}
.subtitle {
  display: flex;
  align-items: center;
  margin-left: 14px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #47536b;
  .icon {
    margin-right: 10px;
  }
}
.shipping-method {
  display: flex;
  flex-direction: column;
  padding-left: 48px;
  .title {
    font-size: 14px;
    line-height: 21px;
    color: #47536b;
    margin-bottom: 8px;
  }
  .description {
    font-size: 13px;
    line-height: 21px;
    color: #8592ad;
  }
}
@media (max-width: 768px) {
  .loader-wrapper {
    margin: 20px 0;
  }
}
</style>
