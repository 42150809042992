var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.shopLogo ? _c('router-link', {
    staticClass: "pointer logo",
    class: {
      disabled: _vm.loadingProducts
    },
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.selectCategory({
          id: null
        });
      }
    }
  }, [_c('div', {
    staticClass: "image image-logo"
  }, [_c('img', {
    attrs: {
      "src": _vm.shopLogo
    }
  })])]) : _vm._e(), _vm.categories && _vm.categories.length ? _c('div', {
    staticClass: "categories"
  }, [_c('router-link', {
    staticClass: "categorie",
    class: {
      'categorie-active': !_vm.categorieSelected || !_vm.categorieSelected.id,
      disabled: _vm.loadingProducts
    },
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.selectCategory({
          id: null
        });
      }
    }
  }, [_c('div', {
    staticClass: "wrapper-name"
  }, [_vm._v(" INICIO "), !_vm.categorieSelected || !_vm.categorieSelected.id ? _c('div', {
    staticClass: "app-loading"
  }, [_c('app-loading', {
    attrs: {
      "loading": _vm.loadingProducts,
      "size": "small"
    }
  })], 1) : _vm._e()])]), _vm._l(_vm.isMobile ? _vm.categories : _vm.categories.slice(0, 6), function (category, index) {
    return _c('router-link', {
      key: index,
      staticClass: "categorie has-tooltip-arrow has-tooltip-bottom",
      class: {
        'categorie-active': _vm.categorieSelected && _vm.categorieSelected.id == category.id,
        disabled: _vm.loadingProducts
      },
      attrs: {
        "data-tooltip": category.name.length > 15 && !_vm.isMobile ? category.name : null,
        "to": {
          name: 'category',
          params: {
            category: _vm.paramsProductRoute(category.name) + '-' + category.id
          }
        }
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.selectCategory(category);
        }
      }
    }, [_c('div', {
      staticClass: "wrapper-name"
    }, [_vm._v(" " + _vm._s(category.name) + " "), _vm.categorieSelected && _vm.categorieSelected.id == category.id ? _c('div', {
      staticClass: "app-loading"
    }, [_c('app-loading', {
      attrs: {
        "loading": _vm.loadingProducts,
        "size": "small"
      }
    })], 1) : _vm._e()])]);
  }), !_vm.isMobile && _vm.categories.length > 6 ? _c('div', {
    staticClass: "more"
  }, [_c('div', {
    staticClass: "dropdown is-right is-hoverable"
  }, [_vm._m(0), _c('div', {
    staticClass: "dropdown-menu",
    attrs: {
      "id": "dropdown-menu",
      "role": "menu"
    }
  }, [_c('div', {
    staticClass: "dropdown-content"
  }, _vm._l(_vm.categories.slice(6, _vm.categories.length), function (category, index) {
    return _c('router-link', {
      key: index,
      staticClass: "dropdown-item",
      class: {
        'categorie-active': _vm.categorieSelected && _vm.categorieSelected.id == category.id,
        disabled: _vm.loadingProducts
      },
      attrs: {
        "to": {
          name: 'category',
          params: {
            category: _vm.paramsProductRoute(category.name) + '-' + category.id
          }
        }
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.selectCategory(category);
        }
      }
    }, [_vm._v(" " + _vm._s(category.name) + " ")]);
  }), 1)])])]) : _vm._e()], 2) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown-trigger"
  }, [_vm._v(" MÁS "), _c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-caret-down.svg")
    }
  })])]);
}]

export { render, staticRenderFns }