<template>
  <div v-if="products.length" class="product-not-found">
    <div class="product-not-found__image">
      <img src="@/assets/icon-product-not-found.svg" />
    </div>
    <h2>
      Parece que no encontramos el producto que buscabas
    </h2>

    <div class="product-not-found__buttons">
      <router-link
        class="product-not-found__button product-not-found__button--home"
        :to="{ name: 'home' }"
      >
        Volver al inicio
      </router-link>
      <a
        class="product-not-found__button product-not-found__button--category"
        :href="productCategoryUrl"
      >
        Ver artículos similares
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProductNotFound',
  computed: {
    ...mapState('products', ['products']),
    ...mapState('categories', ['categories']),
    productCategoryUrl() {
      const { category: categoryUrl } = this.$route.params

      return categoryUrl ? `/${categoryUrl}` : '/'
    }
  }
}
</script>

<style lang="scss" scoped>
.product-not-found {
  padding: 100px 0 300px;
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  color: #0f172a;

  &__image {
    margin-bottom: 56px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 48px;
  }

  &__buttons {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__button {
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    width: 260px;
    display: block;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__button--home {
    border: 1px solid #0f172a;
    color: #0f172a;
    margin-right: 24px;

    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__button--category {
    color: #ffffff;
    background: #1e293b;
  }
}
</style>
