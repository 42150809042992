var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.products.length ? _c('div', {
    staticClass: "product-not-found"
  }, [_vm._m(0), _c('h2', [_vm._v(" Parece que no encontramos el producto que buscabas ")]), _c('div', {
    staticClass: "product-not-found__buttons"
  }, [_c('router-link', {
    staticClass: "product-not-found__button product-not-found__button--home",
    attrs: {
      "to": {
        name: 'home'
      }
    }
  }, [_vm._v(" Volver al inicio ")]), _c('a', {
    staticClass: "product-not-found__button product-not-found__button--category",
    attrs: {
      "href": _vm.productCategoryUrl
    }
  }, [_vm._v(" Ver artículos similares ")])], 1)]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-not-found__image"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-product-not-found.svg")
    }
  })]);
}]

export { render, staticRenderFns }