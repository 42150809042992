import { make } from 'vuex-pathify'
import actions from './actions'

const inistialState = () => ({
  cities: null,
  departments: null,
  loadingCities: false,
  identificationTypes: null,
  loadingIdentificationTypes: false
})

const state = inistialState()

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
