<template>
  <Header>
    <div class="level">
      <div class="level-left">
        <div v-if="shop" class="level-item">
          <inline-svg
            @click="openMenu()"
            :src="require('@/assets/icon-menu.svg')"
            class="pointer"
          />
        </div>
      </div>
      <div class="level-center">
        <div v-if="shop" class="level-item">
          <router-link to="/" class="link-logo">
            <img v-if="shopLogo" class="image-logo" :src="shopLogo" />
            <h2 v-else class="title-shop">{{ shop.name }}</h2>
          </router-link>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="shopping-car pointer" @click="goToShoppingCart">
            <inline-svg :src="require('@/assets/icon-shopping-car.svg')" />
            <div v-if="productsInCart.length" class="badge has-text-centered">
              <p>{{ unitsInCart }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-search" v-show="$route.name === 'home' || $route.name === 'category'">
      <app-input-search />
    </div>
  </Header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store'

import AppInputSearch from '@/components/shared/InputSearch'

export default {
  components: {
    AppInputSearch
  },
  computed: {
    ...mapState('cart', ['productsInCart']),
    ...mapState('shop', ['shop']),
    ...mapGetters('cart', ['unitsInCart']),
    ...mapGetters('shop', ['shopLogo'])
  },
  methods: {
    goToShoppingCart() {
      // TODO: Hay un bug que no deja actualizar cantidad del stock en el carrito sin recargar la pagina
      // this.$router.push('/shopping-cart')
      window.location.pathname = '/shopping-cart'
    },
    openMenu() {
      store.set('app/showMenuMobile', true)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background-color: var(--color-secondary);
  position: fixed;
  width: 100%;
  z-index: 5;
  height: 66px;
  .level {
    padding: 0 21px;
    height: 100%;
    margin-bottom: -0.6px !important;
    .level-left {
      svg {
        fill: var(--color-menu);
      }
    }
    .level-center {
      justify-content: center;
      .link-logo {
        display: flex;
        justify-content: center;
        .image-logo {
          min-height: 40px;
          max-height: 50px;
          max-width: 100%;
          img {
            max-height: 60px;
          }
        }
        .title-shop {
          text-transform: uppercase;
          font-size: 18px;
          color: var(--color-text);
          max-width: 220px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .level-right {
      .shopping-car {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        width: 38px;
        height: 38px;
        background: var(--color-cart);
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
        border-radius: 22px;
        svg {
          stroke: var(--color-icon);
        }
        .badge {
          position: absolute;
          top: 23px;
          right: -7px;
          width: 20px;
          height: 20px;
          background: var(--color-contrast);
          color: #fff;
          font-size: 12px;
          padding-top: 1px;
          box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
          border-radius: 22px;
        }
      }
    }
  }
}
.wrapper-search {
  z-index: 9;
  padding: 10px 21px;
  background-color: var(--color-search-container);
}
</style>
