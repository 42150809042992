import store from '@/store'
import { VERSIONS } from '@/utils/constants'

const getters = {
  totalShipping(state) {
    if (!state.shippingMethods?.length) {
      return 0
    }

    const [shippingMethod] = state.shippingMethods
    const minPurchaseForFree = parseFloat(shippingMethod.minPurchaseForFree)
    const rate = parseFloat(shippingMethod.rate)
    const subtotal = parseFloat(store.getters['cart/subtotal'])

    if (minPurchaseForFree && subtotal >= minPurchaseForFree) {
      return 0
    }

    return rate
  },
  shippingSelected(state) {
    if (state.shippingMethods && state.shippingMethods.length > 0) {
      return state.shippingMethods[0]
    }
    return null
  },
  activeCityShipping() {
    const applicationVersion = store.get('shop/shop@config.applicationVersion')

    const { COLOMBIA, REPUBLICA_DOMINICANA, PANAMA, PERU, COSTA_RICA, ARGENTINA, CHILE } = VERSIONS
    const activeCityShippingVersions = [
      COLOMBIA,
      REPUBLICA_DOMINICANA,
      PANAMA,
      PERU,
      COSTA_RICA,
      ARGENTINA,
      CHILE
    ]

    if (activeCityShippingVersions.includes(applicationVersion)) {
      return true
    }

    return false
  }
}

export default getters
