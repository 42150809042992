const variantProductUtil = {}

function errorRequired(product, variantsSelected) {
  let message = ''
  let variantId = null

  if (product.variantAttributes) {
    product.variantAttributes.map((attribute, i) => {
      if (variantsSelected[i] === undefined) {
        message = `Por favor seleccionar ${attribute.name}`
        variantId = attribute.id
      }
    })
  } else {
    message = 'Primero seleccione una variante del producto.'
  }

  if (!message) {
    let variantSelectedText = ''
    variantsSelected.map(variant => {
      variantSelectedText += variant.value + ' / '
    })
    message = `${product.name} no está disponible en ${variantSelectedText}`
  }

  return { message, variantId }
}

variantProductUtil.searchVariant = (product, variantsSelected) => {
  let variant = false
  const res = {
    status: 'SUCCESS', // ERROR || SUCCESS
    variant: undefined,
    error: {}
  }
  if (product.itemVariants) {
    product.itemVariants.map(item => {
      let count = 0
      item.variantAttributes.map(variant => {
        variantsSelected.map(variantSelected => {
          if (variantSelected && variantSelected.idVariant === variant.id && variant.options[0].id === variantSelected.idOption) {
            count++
          }
        })
      })
      if (count === product.variantAttributes.length) {
        variant = item
        variant.idVariantParent = product.id
      }
    })
  }

  if (variant) {
    res.variant = variant
  } else {
    res.status = 'ERROR'
    res.error = errorRequired(product, variantsSelected)
  }

  return res
}

export default variantProductUtil
