<template>
  <div>
    <div v-for="(variant, indexVariant) in variants" :key="indexVariant">
      <h3 class="label-detail">{{ variant.name }}</h3>
      <div class="select is-small">
        <select
          v-model="variantsSelected[indexVariant]"
          :id="`select-${variant.id}`"
          @change="changeSelect"
        >
          <option disabled :value="undefined">Seleccionar {{ variant.name }}</option>
          <option
            v-for="(option, indexOption) in variant.options"
            :key="indexOption"
            :value="{
              idVariant: variant.id,
              idOption: option.id,
              value: option.value
            }"
            :disabled="option.disabled"
          >
            {{ option.value }}
          </option>
        </select>
      </div>
    </div>
    <div class="error-message">
      <span v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: { type: Object, required: true },
    errorMessage: { type: String, required: false, default: '' }
  },
  data: function() {
    return {
      variantsSelected: []
    }
  },
  computed: {
    variants() {
      const variants = [...this.product.variantAttributes]

      if (variants.length === 1) {
        const variant = variants[0]

        variant.options.forEach(variantOption => {
          variantOption.disabled = true

          this.product.itemVariants.forEach(itemVariant => {
            const variantAttribute = itemVariant.variantAttributes[0]

            variantAttribute.options.forEach(({ id }) => {
              if (id !== variantOption.id) return

              const inventory = itemVariant.inventorySettings
              if (
                inventory?.sellWithoutStock ||
                (!inventory?.sellWithoutStock && inventory?.quantity)
              ) variantOption.disabled = false
            })
          })
        })
      } else {
        if (!this.variantsSelected?.length) {
          this.$emit('updateVariantsSelected', this.variantsSelected)
          return variants
        }

        this.variantsSelected.forEach(selectedVariant => {
          variants.forEach(variant => {
            if (variant.id === selectedVariant.idVariant) return

            variant.options.forEach(variantOption => {
              variantOption.disabled = true

              this.product.itemVariants.forEach(itemVariant => {
                const selectedVariantExists = itemVariant.variantAttributes.find(({ id }) => id === selectedVariant.idVariant)
                const selectedOptionExists = selectedVariantExists?.options.find(({ id }) => id === selectedVariant.idOption)
                if (!selectedOptionExists) return

                itemVariant.variantAttributes.forEach(variantAttribute => {
                  if (variantAttribute.id === selectedVariant.idVariant) return

                  variantAttribute.options.forEach(({ id }) => {
                    if (id !== variantOption.id) return

                    const inventory = itemVariant.inventorySettings
                    if (
                      inventory?.sellWithoutStock ||
                      (!inventory?.sellWithoutStock && inventory?.quantity)
                    ) variantOption.disabled = false
                  })
                })
              })
            })
          })
        })
      }

      this.$emit('updateVariantsSelected', this.variantsSelected)
      return variants
    }
  },
  methods: {
    changeSelect(event) {
      event.target.classList.remove('error')
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  margin: 10px 0;
  width: 100%;
  select {
    width: 100%;
  }
}
.label-detail {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.error-message {
  min-height: 30px;
  color: #e63f3f;
  font-size: 13px;
}
</style>
