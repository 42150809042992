/* Validations for Vuelidate */
import { IDENTIFICATION_PERU_DNI_FORMAT_LENGTH } from './constants'

export const isValidDNIPeru = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'DNI') return true

  if (value?.length !== IDENTIFICATION_PERU_DNI_FORMAT_LENGTH) return false

  return true
}
