<template>
  <div v-if="addedProductModalIsOpen && product" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <h1>Tu producto se agregó al carrito</h1>
      <div class="columns">
        <div class="column is-3 product-img">
          <img v-if="product.mainImage" :src="product.mainImage" />
          <img v-else src="@/assets/icon-item-default.svg" />
        </div>
        <div class="column is-9">
          <h2>{{ product.name }}</h2>
          <p class="quantity" v-if="productQuantity">Cantidad: {{ productQuantity }}</p>
          <p>Precio por unidad</p>
          <p class="price">{{ $currencyFormatter(product.finalPrice) }}</p>
        </div>
      </div>
      <button class="button is-fullwidth go-cart" @click="goToShoppingCart()">IR AL CARRITO</button>
      <button class="button is-fullwidth close" @click="closeModal(true)">SEGUIR COMPRANDO</button>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeModal()"></button>
  </div>
</template>

<script>
import store from '@/store'
import { get } from 'vuex-pathify'

export default {
  computed: {
    product: get('products@lastAddedProduct.product'),
    productQuantity: get('products@lastAddedProduct.quantity'),
    addedProductModalIsOpen: get('modal@addedProductModal.isOpen')
  },
  methods: {
    closeModal(home = false) {
      store.set('modal/addedProductModal@isOpen', false)
      if (home) {
        this.$router.push('/')
      }
    },
    goToShoppingCart() {
      // TODO: Hay un bug que no deja actualizar cantidad del stock en el carrito sin recargar la pagina
      // this.$router.push('/shopping-cart')
      window.location.pathname = '/shopping-cart'
      store.set('modal/addedProductModal@isOpen', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-active {
  z-index: 9999;
}
.modal-content {
  background-color: #fff;
  padding: 15px 20px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  width: 500px;
  h1 {
    font-size: 20px;
    color: #29303d;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 18px;
    color: #47536b;
  }
  .quantity {
    margin-bottom: 10px;
  }
  .price {
    font-size: 18px;
    color: #47536b;
  }
  .product-img {
    justify-content: center;
    display: flex;
    img {
      min-height: 80px;
      max-height: 90px;
      max-width: 100%;
    }
  }
  .button:first-of-type {
    margin-bottom: 8px;
  }
  .button.go-cart {
    background: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    border-radius: 0;
    line-height: 24px;
    text-transform: uppercase;
    height: 36px;
  }
  .button.close {
    color: var(--color-tertiary);
    border: 0;
    font-size: 14px;
    line-height: 24px;
    height: 36px;
  }
}
@media (max-width: 768px) {
  .modal-content {
    width: 80%;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
