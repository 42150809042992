import { make } from 'vuex-pathify'
import actions from './actions'
import gettersCustom from './getters'

const inistialState = () => ({
  loadingCartProducts: false,
  productsInCart: [],
  client: null,
  address: null,
  paymentMethod: {},
  billingInfo: null,
  showNoStockWarning: false,
  lastOrderProductsInCart: []
})

const state = inistialState()

const getters = {
  ...gettersCustom,
  ...make.getters(state)
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
