var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_vm.productsInCart && _vm.productsInCart.length == 0 ? _c('div', {
    staticClass: "no-products"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-shopping-car.svg")
    }
  }), _c('h2', [_vm._v("Tu carrito está vacío")]), _c('p', [_vm._v("En " + _vm._s(_vm.shop.name) + " tenemos los mejores productos para ti")]), _c('router-link', {
    staticClass: "button",
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" VER PRODUCTOS ")])], 1) : _vm._e(), _vm.loadingCartProducts ? _c('div', {
    staticClass: "product-cart-loading"
  }, [_c('Loading', {
    attrs: {
      "loading": ""
    }
  })], 1) : _vm._l(_vm.productsInCart, function (product, index) {
    return _c('div', {
      key: index
    }, [_c('CartProduct', {
      attrs: {
        "product": product
      }
    })], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }